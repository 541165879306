.browser-tab {
  @apply relative z-10 w-40 rounded-tl-md rounded-tr-md bg-white p-2;
  &:before {
    content: "";
    @apply absolute -left-2.5 bottom-0 z-20 h-2.5 w-2.5 bg-white;
  }
  &:after {
    content: "";
    @apply absolute -right-2.5 bottom-0 z-20 h-2.5 w-2.5 bg-white;
  }
}
.browser-tab-inner {
  @apply flex items-center justify-between;
  &:before {
    content: "";
    @apply absolute -left-5 bottom-0 z-30 h-5 w-5 rounded-lg bg-gray-200;
  }
  &:after {
    content: "";
    @apply absolute -right-5 bottom-0 z-30 h-5 w-5 rounded-lg bg-gray-200;
  }
}
