.button {
  @include button-default();
}
.button-primary {
  @include button-default();
  @include button-primary();
}
.button-secondary {
  @include button-default();
  @include button-secondary();
}
.button-tertiary {
  @include button-default();
  @include button-tertiary();
}
.button-warn {
  @include button-default();
  @include button-warn();
}
.button-text {
  @include button-default();
  @apply border-transparent shadow-none outline-none;
}
.button-small {
  @include button-default();
  @apply px-2.5 py-1.5 text-sm;
}

.button-rounded {
  @include button-default();
  @apply rounded-full p-2;
}

.button-group {
  button {
    @apply min-w-[50px] rounded-none border-l-0;
  }
  button.button-primary {
    @apply border-grape-300 border-solid;
  }
  button:first-child {
    @apply rounded-l-lg rounded-r-none border-l border-solid;
  }
  button:last-child {
    @apply rounded-l-none rounded-r-lg;
  }
}

.button-toggle-group {
  button {
    @apply min-w-[50px] rounded-none border-l-0 border-solid border-gray-400;
  }
  button:first-child {
    @apply rounded-l-lg rounded-r-none border-l border-solid border-gray-400;
  }
  button:last-child {
    @apply rounded-l-none rounded-r-lg;
  }
}

a.button-primary,
a.button-secondary,
a.button-tertiary {
  @apply no-underline;
}

a.button-primary {
  @apply text-white;
  &:visited {
    @apply text-white;
  }
}

a.button-secondary {
  @apply text-grape-700;
  &:visited {
    @apply text-grape-700;
  }
}

a.button-tertiary {
  @apply text-gray-700;
  &:visited {
    @apply text-gray-700;
  }
}

a.button-warn {
  @apply text-white;
  &:visited {
    @apply text-white;
  }
}
