.tooltip {
  @apply fixed z-50 rounded-md p-3 text-sm;
  &::before {
    content: "";
    @apply absolute h-0 w-0;
  }
  &-visible {
    @apply opacity-100 transition-opacity duration-300;
  }
  &-light {
    @apply bg-white text-gray-900;
    &::before {
      @apply border-[5px] border-solid border-white;
    }
  }

  &-dark {
    @apply bg-gray-900 text-white;
    &::before {
      @apply border-[5px] border-solid border-gray-900;
    }
  }
  &-below {
    @apply mt-[7px] -translate-x-1/2;
    &::before {
      @apply -top-[5px] left-[calc(50%-5px)] border-t-0 border-l-transparent border-r-transparent;
    }
  }

  &-above {
    @apply mb-[7px] -translate-x-1/2 -translate-y-full;
    &::before {
      @apply -bottom-[5px] left-[calc(50%-5px)] border-b-0 border-l-transparent border-r-transparent;
    }
  }

  &-left {
    @apply -translate-y-1/2 translate-x-[calc(-100%-7px)];
    &::before {
      @apply -right-[5px] top-[calc(50%-5px)] border-r-0 border-b-transparent border-t-transparent;
    }
  }

  &-right {
    @apply -ml-[7px] -translate-y-1/2;
    &::before {
      @apply -left-[5px] top-[calc(50%-5px)] border-l-0 border-b-transparent border-t-transparent;
    }
  }
  &-dynamic {
    @apply ml-5;

    &::before {
      @apply hidden;
    }
  }
}
